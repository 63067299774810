var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TButton',{staticClass:"mb-3 px-4",attrs:{"content":"Create contract","icon":"cil-folder-plus","to":_vm.lodash.getReferenceLink('contract','new'),"messageOptions":{ bold: true }}}),_c('TTableAdvance',{attrs:{"items":_vm.contracts,"fields":_vm.fields,"store":"order.contracts","reloadable":"","resource":"contracts","enterable":"","deletable":""},on:{"click-clear-filter":_vm.clearFilter,"click-reload":_vm.refresh},scopedSlots:_vm._u([{key:"status_id",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('SMessageContractStatus',{attrs:{"status":item.status_id}})],1)]}},{key:"customer",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TLink',{attrs:{"content":item.customer ? item.customer.name : item.customer_id,"to":_vm.lodash.getReferenceLink('user',item.customer_id)}})],1)]}},{key:"service_fee",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.service_fee,"currency":item.service_fee_currency_id}})],1)]}},{key:"purchase_cost_outstanding",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.purchase_cost_outstanding,"currency":item.purchase_cost_currency_id}})],1)]}},{key:"transport_organization_id",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('SMessageOrganization',{attrs:{"id":item.transport_organization_id}})],1)]}},{key:"sales_organization_id",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('SMessageOrganization',{attrs:{"id":item.sales_organization_id}})],1)]}},{key:"service_fee_outstanding",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.service_fee_outstanding,"currency":item.service_fee_currency_id}})],1)]}},{key:"updated_at",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageDateTime',{attrs:{"content":item.updated_at,"small":""}})],1)]}},{key:"id-filter",fn:function(){return [_c('TInputText',{attrs:{"value":_vm.filter.id,"placeholder":"Contract Id"},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "id", $event)},_vm.filterChange]}})]},proxy:true},{key:"status_id-filter",fn:function(){return [_c('SSelectContractStatus',{attrs:{"value":_vm.filter.status_id},on:{"update:value":function($event){return _vm.$set(_vm.filter, "status_id", $event)},"change":_vm.filterChange}})]},proxy:true},{key:"transport_organization_id-filter",fn:function(){return [_c('SSelectOrganization',{attrs:{"value":_vm.filter.transport_organization_id},on:{"update:value":function($event){return _vm.$set(_vm.filter, "transport_organization_id", $event)},"change":_vm.filterChange}})]},proxy:true},{key:"sales_organization_id-filter",fn:function(){return [_c('SSelectOrganization',{attrs:{"value":_vm.filter.sales_organization_id},on:{"update:value":function($event){return _vm.$set(_vm.filter, "sales_organization_id", $event)},"change":_vm.filterChange}})]},proxy:true},{key:"customer-filter",fn:function(){return [_c('SSelectCustomer',{attrs:{"value":_vm.filter.customer_id,"noCustomLabel":"","prependAll":""},on:{"update:value":function($event){return _vm.$set(_vm.filter, "customer_id", $event)},"change":_vm.filterChange}})]},proxy:true},{key:"updated_at-filter",fn:function(){return [_c('TInputDateTimeRange',{attrs:{"value":_vm.dateRange},on:{"update:value":[function($event){_vm.dateRange=$event},_vm.setUpdatedAtFilter]}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }